<template>
  <div class="citizen-charter-wrapper">
    <div class="mb-32 component__header">
      <div class="container">
        <h1>नागरिक वडापत्र</h1>
      </div>
    </div>
    <TopFilter
      :filters="top_filters"
      @changeInTopFilter="handleTopFilter"
    ></TopFilter>
    <div class="citizen-charter__content">
      <div class="flex">
        <SideFilter
          @changeInRadioFilter="handleRadioFilter"
          :service_type_arr="service_types"
          :service_provider_arr="service_providers"
          v-if="showSideBar"
        ></SideFilter>
        <div class="main-container echarter-main-container">
          <div class="download-pdf-container">
            <button
              @click="$store.dispatch('windowPrint')"
              class="btn download-pdf"
            >
              PDF डाउनलोड गर्नुहोस्
            </button>
          </div>
          <div class="citizen-charter" v-if="charters.length">
            <div
              class="mb-16 flex citizen-charter__cards"
              v-for="(ele, index) in charters"
              :key="index"
              @click="goToDetail(ele.id)"
            >
              <div class="citizen-charter__cards-title">
                {{ ele.name }}
              </div>
              <a
                @click="goToDetail(ele.id)"
                class="flex more"
                title="थप जानकारी"
                >थप जानकारी <i class="arrow-right"></i
              ></a>
            </div>
          </div>
          <NoDataFound v-else />
        </div>
      </div>
    </div>
  </div>
  <!-- For PDF print -->
  <PdfDownload ref="pdf">
    <template v-slot:content>
      <div v-if="charters.length">
        <div class="flex flex-title">
          <h1 class="mb-16">नागरिक वडापत्र</h1>
          <span class="selected-filter">{{ getTopFilter }}</span>
        </div>
        <div class="flex flex-filter">
          <span class="" v-if="this.selections.service_provider.length"
            ><strong>सेवा दिने कार्यालय:</strong> {{ getServiceProvider }}</span
          >
          <span class="" v-if="this.selections.service_type.length"
            ><strong>सेवाको प्रकार:</strong> {{ getServiceType }}</span
          >
        </div>
        <div class="mb-24" v-for="(ele, index) in charters" :key="index">
          <h2 class="mb-16">{{ ele.name }}</h2>
          <table>
            <tbody>
              <tr>
                <td class="service-charge">सेवा शुल्क</td>
                <td>{{ ele.misc_data.service.service_charge }}</td>
              </tr>
              <tr>
                <td class="service-duration">लाग्ने समय</td>
                <td>{{ ele.misc_data.service.service_duration }}</td>
              </tr>
              <tr>
                <td class="service-provider">सेवा प्रदान गर्ने कार्यालय</td>
                <td>{{ ele.misc_data.service.service_provider.label }}</td>
              </tr>
              <tr>
                <td class="responsible-branch">जिम्मेवार शाखा</td>
                <td>{{ ele.misc_data.responsible.responsible_branch }}</td>
              </tr>
              <tr>
                <td class="responsible-room-number">कोठा नं</td>
                <td>{{ ele.misc_data.responsible.room_no }}</td>
              </tr>
              <tr>
                <td class="responsible-contact-number">सम्पर्क नं</td>
                <td>{{ ele.misc_data.responsible.contact_number }}</td>
              </tr>
              <tr>
                <td class="service-grievance">
                  सेवा सम्बन्धित गुनासो सुन्ने जिम्मेवार अधिकारी
                </td>
                <td>{{ ele.misc_data.responsible.responsible_official }}</td>
              </tr>
              <tr>
                <td class="service-grievance-listener">
                  सेवा सम्बन्धित गुनासो सुन्ने प्रक्रिया
                </td>
                <td>{{ ele.misc_data.service.service_grievance_official }}</td>
              </tr>
              <tr>
                <td class="service-form-link">
                  सम्बन्धित सेवा प्राप्त गर्न भर्नु पर्ने फारम
                </td>
                <td>{{ ele.misc_data.service.service_form_link }}</td>
              </tr>
              <tr>
                <td>
                  प्रक्रिया / सेवा सुबिधा प्राप्त गर्न आवश्यक पर्ने प्रक्रियाहरु
                </td>
                <td>{{ ele.misc_data.service.service_procedure }}</td>
              </tr>
              <tr>
                <td>आवश्यक पर्ने कागजातहरु</td>
                <td>{{ ele.misc_data.responsible.required_documents }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </PdfDownload>
</template>

<script>
import { defineAsyncComponent } from "vue";
import LocalGovernmentRepo from "../../repositories/LocalGovernmentRepository";
import PdfDownload from "./../../components/shared/PdfDownload.vue";
const TopFilter = defineAsyncComponent(() =>
  import("../../components/citizenChater/TopFilter.vue")
);
const SideFilter = defineAsyncComponent(() =>
  import("../../components/citizenChater/SideFilter.vue")
);
const NoDataFound = defineAsyncComponent(() =>
  import("../../components/NoDataFound.vue")
);

export default {
  components: {
    TopFilter,
    SideFilter,
    NoDataFound,
    PdfDownload,
  },
  data() {
    return {
      metadata: {},
      service_types: [],
      service_providers: [],
      top_filters: [],
      year: "",
      charters: [],
      selections: {
        service_provider: "",
        service_type: "",
        top_filter: "",
      },
      headers: [
        "सेवाको नाम",
        "सेवा शुल्क",
        "लाग्ने समय",
        "सेवा प्रदान गर्ने कार्यालय",
        "जिम्मेवार शाखा",
        "कोठा नं",
      ],
    };
  },
  async mounted() {
    let { data } = await LocalGovernmentRepo.getServicesMetadata();
    this.metadata = data.data;
    this.service_types = data.data.types;
    this.service_providers = data.data.service_providers;
    this.top_filters = data.data.service_related_wmg;
    let len = data.data.years.length;
    if (len) {
      let years = data.data.years[len - 1];
      this.year = years.year_en || "";
    }
    this.$store.dispatch("setLoading", false);
  },
  methods: {
    handleRadioFilter(event) {
      this.selections[event.type] = event.value;
    },
    handleTopFilter(event) {
      this.selections.top_filter = event;
    },
    goToDetail(id) {
      this.$router.push(`/citizen-charter/${id}`);
    },
    async getCharterList() {
      this.$store.dispatch("setLoading", true);
      let params = "";
      if (this.year) {
        params += `?year=${this.year}`;

        if (this.selections.top_filter != "") {
          params += `&service_related_wmg=${this.selections.top_filter}`;
        } else {
          if (this.selections.service_provider) {
            params += `&service-providers=${this.selections.service_provider}`;
          }
          if (this.selections.service_type) {
            params += `&serviceType=${this.selections.service_type}`;
          }
        }
        let { data } = await LocalGovernmentRepo.getServicesFiltered(params);
        this.charters = data.data;
      }
      this.$store.dispatch("setLoading", false);
    },
  },
  computed: {
    showSideBar: function () {
      if (
        this.selections.top_filter === "" ||
        this.selections.top_filter === "all"
      ) {
        return true;
      }
      return false;
    },
    getTopFilter() {
      if (this.top_filters.length) {
        let search = this.top_filters.find((ele) => {
          if (this.selections.top_filter == ele.id) {
            return ele;
          }
        });

        if (search) {
          return search.label;
        }
      }

      return "सबै सेवाहरु";
    },
    getServiceProvider() {
      if (this.service_providers.length) {
        let search = this.service_providers.find((ele) => {
          if (this.selections.service_provider == ele.key) {
            return ele;
          }
        });

        if (search) {
          return search.name_ne;
        }
      }

      return "";
    },
    getServiceType() {
      if (this.service_types.length) {
        let search = this.service_types.find((ele) => {
          if (this.selections.service_type == ele.id) {
            return ele;
          }
        });

        if (search) {
          return search.label;
        }
      }

      return "";
    },
  },
  watch: {
    selections: {
      handler: "getCharterList",
      deep: true,
    },
    year: {
      handler: "getCharterList",
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data-found {
  max-width: 855px;
}
.citizen-charter {
  margin: 0 auto;
  max-width: 855px;
  &-wrapper {
    padding-bottom: 40px;
    .filter-sidebar {
      margin-top: 56px;
      @media all and (max-width: 768px) {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
          0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        display: none;
        height: 100%;
        left: 0;
        margin: 0;
        position: fixed;
        top: 0;
        z-index: 10;
      }
    }
  }
  &__content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;
    > .flex {
      align-items: flex-start;
    }
  }
  &__cards {
    background: $neutrals-white;
    border-radius: 12px;
    padding: 20px 32px;
    cursor: pointer;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;

    @media all and (max-width: 600px) {
      display: block;
    }

    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
    }
    &-title {
      font-size: 20px;
      font-weight: 700;
    }
  }
}
</style>
